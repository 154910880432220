import React from 'react'
import * as styles from './404.module.scss'
import Layout from "../components/layout"

const Missing = () => {
    return (
    <Layout>
    <div className={styles.content}>
        <h1 className={styles.header}>Page not found</h1>
        <p className={styles.errorMessage}>You have unfornuately arrived up a missing star in the universe</p>
    </div>
    </Layout>
    )
}

export default Missing